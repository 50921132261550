import { preloadImages, preloadFonts } from './utils';
import LocomotiveScroll from 'locomotive-scroll';


if (document.querySelector('[data-scroll-container]')) {
	// Initialize Locomotive Scroll (horizontal direction)
	const scroll = new LocomotiveScroll({
		el: document.querySelector('[data-scroll-container]'),
		smooth: true,
		direction: 'horizontal',
		gestureDirection: 'both',
		smartphone: { smooth: true, direction: 'vertical' },
		table: { smooth: true, direction: 'vertical' },
	});
	window.scroll = scroll;
}


// Preload images and fonts
Promise.all([preloadImages('.gallery__item-imginner')]).then(() => {
	// Remove loader (loading class)
	document.body.classList.remove('loading');
});



const menu = document.querySelector("#menu")
const link_stories = `
<li><a class="programme_blue_marble" href="programme_blue_marble.html">Programme Blue Marble </a></li>
<li><a class="halles_du_faubourg" href="halles_du_faubourg.html">Les Halles du Faubourg </a></li>
<li><a class="inventaire_du_monde" href="inventaire_du_monde.html">L'inventaire du Monde </a></li>
`
const link_commands = `
<li><a class="portrait" href="Portrait.html">Portrait</a></li>
<li><a class="culinaire" href="Culinaire.html">Culinaire</a></li>
<li><a class="reportage" href="Reportage.html">Reportage</a></li>
`

const link_about_us = `
<li><a class="bio" href="Bio.html">Bio</a></li>
<li><a class="actualité" href="Actualité.html">Actualité</a></li>
<li><a class="publications" href="Publications.html">Publications/Expos</a></li>
`

menu.innerHTML = `
<div class="menu" style="display:flex; justify-content: center; align-items: center; font-size: 1rem; color: black;">
			<div style="height: 35vh;">
				<h1><a href="index.html">Adrien PINON</a></h1>
				<div>
					<ul>
						<li id="stories" >Récits <span style="font-size: 0.75rem;"> / Projets d'auteur </span>
							<div class="submenu" style="overflow-y: hidden;" id="submenu_stories">
								<ul>`
	+ link_stories +
	`
								</ul>
							</div>
						</li>
						<li id="commands">Commandes  <span style="font-size: 0.75rem;"> / Projets professionnels </span>
							<div class="submenu" style="overflow-y: hidden;" id="submenu_commands" >
								<ul>`
	+ link_commands +
	`</ul>
							</div>
						</li>
						</li>
						<li id="about_us">À propos
							<div class="submenu" style="overflow-y: hidden;" id="submenu_about_us" >
								<ul>`
	+ link_about_us +
	`</ul>
							</div>
						</li>
						<li><a href="Contact.html">Contact</a></li>
						<li><a href="https://www.instagram.com/adrien.pinon/" target="_blank">Instagram</a></li>
						<li><a href="mentions_legales.html" style="font-size: 0.65rem; font-style: italic;">Mentions légales</a></li>
					</ul>
				</div>
			</div>
			
			</div>
			<div id="menu_smartphone" class="menu_smartphone">
				<button id="extend_menu_smartphone" class="extend_menu_smartphone" >
					<svg width="50" height="50" viewBox="0 0 100 100">
					<path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
					<path class="line line2" d="M 20,50 H 80" />
					<path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
					</svg>
				</button>
				<div style="position:absolute; top:25px; left:25% ; font-size: 1.5rem;text-align:center;"><a href="index.html">ADRIEN PINON</a></div>
				<div style="display:flex; justify-content: center; align-items: center; font-size:1rem; color: black; height: 70vh; text-align: center; margin-top: 25px;">
				<div>
				<ul>
					<li id="stories_phone" >Récits
						<div class="submenu" style="overflow-y: hidden;" id="submenu_stories_phone" >
							<ul>`
	+ link_stories +
	`
							</ul>
						</div>
					</li>
					<li id="commands_phone" >Commandes
						<div class="submenu" style="overflow-y: hidden;" id="submenu_commands_phone" >
							<ul>`
	+ link_commands +
	`
							</ul>
						</div>
					</li>
					<li id="about_us_phone" >À propos
						<div class="submenu" style="overflow-y: hidden; " id="submenu_about_us_phone" >
							<ul>`
	+ link_about_us +
	`
							</ul>
						</div>
					</li>
					<li><a href="Contact.html">Contact</a></li>
					<li><a href="https://www.instagram.com/adrien.pinon/" target="_blank">Instagram</a></li>
					<li><a href="mentions_legales.html" style="font-size: 0.75rem; font-style: italic;">Mentions légales</a></li>
				</ul>
				</div>
				
			</div>
			</div>
`;





/* extend stories menu */
var stories = document.getElementById("stories")
var submenu_stories = document.getElementById("submenu_stories")
var closed_submenu_stories = false
var submenu_stories_height = submenu_stories.offsetHeight
stories.onclick = function () {
	if (closed_submenu_stories) {
		submenu_stories.style.height = submenu_stories_height + "px"
		closed_submenu_stories = false
	}
	else {
		submenu_stories.style.height = "0px"
		closed_submenu_stories = true
	}

}


/* extend commands menu */
var commands = document.getElementById("commands")
var submenu_commands = document.getElementById("submenu_commands")
var closed_submenu_commands = false
var submenu_commands_height = submenu_commands.offsetHeight
commands.onclick = function () {
	if (closed_submenu_commands) {
		submenu_commands.style.height = submenu_commands_height + "px"
		closed_submenu_commands = false
	}
	else {
		submenu_commands.style.height = "0px"
		closed_submenu_commands = true
	}
}


/* extend about_us menu */
var about_us = document.getElementById("about_us")
var submenu_about_us = document.getElementById("submenu_about_us")
var closed_submenu_about_us = false
var submenu_about_us_height = submenu_about_us.offsetHeight
about_us.onclick = function () {
	if (closed_submenu_about_us) {
		submenu_about_us.style.height = submenu_about_us_height + "px"
		closed_submenu_about_us = false
	}
	else {
		submenu_about_us.style.height = "0px"
		closed_submenu_about_us = true
	}
}


// // depending on page we need to closed page
if (!link_stories.includes(decodeURI(window.location.pathname.replace("/", "")))) {
	stories.click();
}

// // depending on page we need to closed page
if (!link_commands.includes(decodeURI(window.location.pathname.replace("/", "")))) {
	commands.click();
}

// // depending on page we need to closed page
if (!link_about_us.includes(decodeURI(window.location.pathname.replace("/", "")))) {
	about_us.click();
}



// highlight the current page menu
var selected_menu = document.getElementsByClassName(decodeURI(window.location.pathname.replace("/", "")).replace(".html", ""));
if (selected_menu.length) {
	for (let item of selected_menu) {
		item.style.opacity = 1;
	}
}

// 
// SMARTPHONE PART
//



/* extend stories menu on smartphone */
var stories_phone = document.getElementById("stories_phone")
var submenu_stories_phone = document.getElementById("submenu_stories_phone")
var closed_submenu_stories_phone = false
var submenu_stories_phone_height = submenu_stories_phone.offsetHeight
stories_phone.onclick = function () {
	if (closed_submenu_stories_phone) {
		submenu_stories_phone.style.height = submenu_stories_phone_height + "px"
		closed_submenu_stories_phone = false
	}
	else {
		submenu_stories_phone.style.height = "0px"
		closed_submenu_stories_phone = true
	}

}


/* extend commands menu */
var commands_phone = document.getElementById("commands_phone")
var submenu_commands_phone = document.getElementById("submenu_commands_phone")
var closed_submenu_commands_phone = false
var submenu_commands_phone_height = submenu_commands_phone.offsetHeight
commands_phone.onclick = function () {
	if (closed_submenu_commands_phone) {
		submenu_commands_phone.style.height = submenu_commands_phone_height + "px"
		closed_submenu_commands = false
	}
	else {
		submenu_commands_phone.style.height = "0px"
		closed_submenu_commands_phone = true
	}
}


/* extend about_us menu */
var about_us_phone = document.getElementById("about_us_phone")
var submenu_about_us_phone = document.getElementById("submenu_about_us_phone")
var closed_submenu_about_us_phone = false
var submenu_about_us_phone_height = submenu_about_us_phone.offsetHeight
about_us_phone.onclick = function () {
	if (closed_submenu_about_us_phone) {
		submenu_about_us_phone.style.height = submenu_about_us_phone_height + "px"
		closed_submenu_about_us_phone = false
	}
	else {
		submenu_about_us_phone.style.height = "0px"
		closed_submenu_about_us_phone = true
	}
}


// depending on page we need to closed page
if (!link_stories.includes(decodeURI(window.location.pathname.replace("/", "")))) {
	stories_phone.click();
}

// depending on page we need to closed page
if (!link_commands.includes(decodeURI(window.location.pathname.replace("/", "")))) {
	commands_phone.click();
}

// depending on page we need to closed page
if (!link_about_us.includes(decodeURI(window.location.pathname.replace("/", "")))) {
	about_us_phone.click();
}




var extend_menu_smartphone = document.getElementById("extend_menu_smartphone");
var menu_smartphone = document.getElementById("menu_smartphone");

var closed = true
extend_menu_smartphone.onclick = function () {
	this.classList.toggle('opened'); this.setAttribute('aria-expanded', this.classList.contains('opened'))
	if (closed) {
		menu_smartphone.style.height = "100vh"
		closed = false		
	}
	else {
		menu_smartphone.style.height = "100px"
		closed = true
	}
}

if (window.location.pathname == "/"){
	stories.click();
	commands.click();
	about_us.click();
	stories_phone.click();
	commands_phone.click();
	about_us_phone.click();
}
